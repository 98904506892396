@import 'variables.scss';

.app {
  color: $white;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.container {
  padding: 0 1rem;

  /* this is for the diagonal backgrounds */
  position: relative;
}

@media screen and (min-width: 1199px) {
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
}

h1,
h2,
h3 {
  margin: 0.33rem 0 0;
  letter-spacing: 0.0375rem;
}

h1 {
  font-size: 32px;
  // line-height: 38px;
}

h2 {
  font-size: 16px;
  // line-height: 26px;
  // font-weight: 400;

  color: $servesaMedium;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

h3 {
  font-size: 24px;
  line-height: 30px;
}

p {
  line-height: 1.5em;
  letter-spacing: 0.01rem;
}

a {
  color: $linkBlue;
  text-decoration: none;
  transition: 120ms ease-in-out;
  // border-bottom: dashed 1px;
}

a:hover {
  color: $linkBlue;
  text-decoration: none;
}

// b {
//   letter-spacing: 0.025rem;
// }

// margins for spacing around components
.spacer-1 {
  margin-top: 1rem;
}

.spacer-2 {
  margin-top: 1.5rem;
}

.spacer-60 {
  margin-top: 60px;
}

/* Buttons styles start */
button.image-button,
button.image-button:active,
button.image-button:focus {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: $black;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Button styles end */

.grid-2,
.grid-x-2,
.grid-3 {
  display: grid;
  gap: 2em;
}

@media screen and (min-width: 999px) {
  .grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
  }

  .grid-x-2 {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1em;
  }

  .grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
  }
}

// Homepage styles

.section-header {
  opacity: 0.55;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 24px;
}

// gray stripe between sections
.stripe {
  font-size: 1.2em;
  font-weight: bold;
  font-family: 'Roboto-Bold';
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding: 10px;

  transform: skewY(-4deg);
  background: $servesaDark;
  box-shadow: $box-shadow-inner;
  z-index: 1;
}

// offset boxes in first section
@media screen and (min-width: 450px) {
  .pull-up {
    margin-top: -60px;
  }

  // .pull-down {
  //   margin-bottom: -60px;
  // }
}

ul.bio > li:nth-child(1) {
  list-style: '⚙️ ';
  list-style-position: outside;
}

ul.bio > li:nth-child(2) {
  margin-top: 10px;
  list-style: '👩‍💼  ';
  list-style-position: outside;
}

ul.bio > li:nth-child(3) {
  margin-top: 10px;
  list-style: '📬  ';
  list-style-position: outside;
}

.panel {
  padding: 22px 22px 0 22px;
  background-color: $black;
  box-shadow: 2px 2px 6px #1f1f1f;
}

a .panel {
  margin-bottom: 2rem;

  padding: 1rem 1rem 0 1rem;
  color: $white;

  transition: 120ms ease-in-out;
}

.profile-pic {
  float: left;
  height: 90px;
  margin: 0 1rem 0rem 0;
  border: solid 2px $servesaGray;
  border-radius: 50%;
}

/* Header */
header {
  transform: skewY(-4deg);
  padding: 1rem 1rem 0.67rem;
}

header a,
header a:hover {
  color: $white;
}

header .title {
  font-family: 'Roboto-Bold';
  font-size: 1.4em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

footer {
  padding: 60px 0 0;
}

@media screen and (min-width: 450px) {
  header {
    // display: grid;
    // grid-template-columns: 4fr 2fr;
    transform: skewY(-4deg);
    // padding: 3rem 2rem 2rem;
  }
}

@media screen and (min-width: 800px) {
  header .title {
    margin-right: 1em;
    font-size: 3em;
  }
}

@media screen and (min-width: 999px) {
  header .title {
    // text-align: right;
  }
}

/* Social Links */
.social-label {
  display: flex;
  align-items: center;
  font-size: small;
}

.social-label-icon {
  width: 1rem;
  margin-right: 0.1rem;
  padding: 4px;
  border-radius: 25%;
  box-sizing: content-box;
}

.social-label .account {
  display: block;
  font-size: smaller;
}

ul.social-links {
  margin-bottom: 0;
  margin-top: 0.25rem;
  padding-left: 0;
}

ul.social-links > li {
  display: inline;
  flex: 0 1 auto;
  list-style-type: none;
  margin-right: 1rem;
}

@media screen and (min-width: 450px) {
  // ul.social-links {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: stretch;
  //   justify-content: space-around;
  //   width: 100%;
  //   margin: 0;
  //   padding: 0;
  // }

  // ul.social-links > li {
  //   display: block;
  //   margin-bottom: 0.75rem;
  // }

  .social-container {
    display: flex;
    align-items: center;
  }

  .social-label {
    font-size: unset;
  }

  .social-label-icon {
    padding: 7px;
  }
}

//
