$black: #282929;
$white: #dddddd;
$linkBlue: #60c8e8;
$yellow: #ffc107;

$servesaDark: lighten(
  $color: $black,
  $amount: 9,
);
$servesaMedium: lighten(
  $color: $black,
  $amount: 23,
);
$servesaGray: lighten(
  $color: $black,
  $amount: 60,
);

$box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);
$box-shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -1px rgba(0, 0, 0, 0.06);
$box-shadow-light: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
  0 1px 2px 0 rgba(0, 0, 0, 0.06);
$box-shadow-inner: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
