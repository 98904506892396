html,
body,
.app {
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #282929;
}

.clear:after {
  content: "";
  display: table;
  clear: both;
}



@media screen and (min-width: 992px) {}