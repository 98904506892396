@import 'css/variables.scss';

.project-card {
  display: grid;
  grid-template-rows: auto 1fr auto;
  white-space: pre-line;
}

.project-card-status {
  color: $white;
}

.status-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;

  border-top: solid 1px $servesaDark;
  padding-top: 1rem;
}

.status-label {
  color: $servesaGray;
  // font-size: smaller;
  text-transform: uppercase;

  // text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: $servesaGray;
  letter-spacing: 0.05rem;
}

.status-content {
  color: $white;
  // font-size: smaller;
}
