@import 'css/variables.scss';

.post-header {
  .breadcrumbs {
    font-size: smaller;
    color: $servesaGray;
  }

  h1 {
    margin-top: 1.5rem;
  }
}

.post-title {
  color: $white;
  margin: 14px 0;
  font-size: 28px;
  line-height: 34px;
}

.date {
  color: #888;
  // border-bottom: $servesaDark solid 1px;
  font-size: small;
  min-height: 1rem;
  margin-bottom: 7px;
  text-transform: uppercase;
}

.excerpt {
  font-size: smaller;
  // font-style: italic;
  margin-top: 12px;
  color: $servesaGray;
}

.byline {
  color: $servesaGray;
  border-top: solid 2px $servesaGray;
  margin: 0 -1rem;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 1rem;
}

.tag {
  display: inline-block;

  color: $servesaGray;
  line-height: initial;
  font-size: smaller;
  text-transform: uppercase;
  padding: 5px 8px 4px;

  border: solid 2px $servesaDark;
  border-radius: 3px;

  margin: 0 8px 6px 0;
}

a.tag:hover {
  color: $linkBlue;
  border: solid 2px $linkBlue;
}

hr {
  border-top: solid 2px $servesaGray;
  margin: 2rem 0;
}

.content {
  h2 {
    margin: 0 0 20px;
    padding: 12px 0 0;
  }

  h3 {
    margin: 0 0 18px;
    padding: 12px 0 0;
    font-size: 1.17rem;
    font-family: 'Roboto-Bold';
  }

  blockquote {
    margin: 2rem 0.25rem;
    padding: 1rem;

    background: $servesaDark;
    border-left: solid 2px gray;

    font-family: serif;
    font-style: italic;
  }

  pre,
  code,
  kbd {
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: auto;

    padding: 1rem;
    background-color: #1d2020;
    border-radius: 8px;
  }

  pre code {
    color: $yellow;
    font-size: 10px;
  }
}

@media screen and (min-width: 445px) {
  .byline {
    border-top: solid 1px $servesaGray;
  }

  // .tag {
  //   border: solid 1px $servesaGray;
  // }

  .post {
    max-width: 44rem;
    margin: 0 auto;
  }

  .content {
    padding: 0 1em;

    h2 {
      margin: 0 0 20px -1rem;
    }

    h3 {
      margin: 0 0 20px -1rem;
    }

    blockquote {
      margin: 2rem;
      padding: 1rem 1.5rem;
      font-style: italic;
    }
  }
}

.dots {
  font-size: 4rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  color: $servesaGray;
}

.cta-panel {
  max-width: 32rem;
  margin: 0 auto;

  text-align: center;
  padding: 1rem 2rem;

  background: $servesaDark;
  border: solid 1px $servesaMedium;
  border-radius: 8px;
}

/* Post */
.post-card {
  /* height: 100%; */
  padding: 22px;
  background: $black;
  border: solid 1px #2d2d2d;
  border-right: solid 1px #232323;
  border-bottom: solid 1px #232323;
  box-shadow: 2px 2px 6px #1f1f1f;
}

.post-content > h3 {
  margin-top: 0.75em;
  margin-bottom: 0.25em;
}

.post-content > p {
  margin-top: 0;
  font-size: smaller;
}

.post-content > time {
  float: right;

  color: #929292;
  font-size: smaller;
  line-height: 1.75em;
}

.post-img {
  height: 200px;
  overflow: hidden;
  background-position: top;
  background-size: cover;

  margin: 0 -0.25rem;
  box-shadow: inset 0 0 9px #222;
}

.small {
  height: 280px;
}

.post-content {
  padding: 0 0 0 1em;
  /* border: 1px solid #4c4b4b; */
  /* border-top: 1px solid #313131; */
}

// Koenig cards(?)
.kg-card {
  figcaption {
    text-align: center;
    font-size: smaller;
    padding: 0.667rem;
    font-style: italic;

    background-color: $servesaDark;
    // border-bottom: solid 1px $servesaGray;
    box-shadow: $box-shadow-inner;
  }
}

.kg-image-card {
  margin: 2rem -1rem;
}

.kg-image {
  max-width: 100%;
}

// from https://ghost.org/docs/api/v2/handlebars-themes/editor/#bookmark-card
.kg-bookmark-card {
  width: 100%;
  position: relative;
}

.kg-bookmark-card {
  width: 100%;
  position: relative;
}

.kg-bookmark-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  color: currentColor;
  font-family: inherit;
  text-decoration: none;
  border: 1px solid $servesaDark;
}

.kg-bookmark-container:hover {
  text-decoration: none;
}

.kg-bookmark-content {
  flex-basis: 0;
  flex-grow: 999;
  padding: 20px;
  order: 1;

  background-color: $servesaDark;
}

.kg-bookmark-title {
  font-weight: 600;
}

.kg-bookmark-metadata,
.kg-bookmark-description {
  margin-top: 0.5em;
}

.kg-bookmark-metadata {
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kg-bookmark-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.kg-bookmark-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: text-bottom;
  margin-right: 0.5em;
  margin-bottom: 0.05em;
}

.kg-bookmark-thumbnail {
  display: flex;
  flex-basis: 24rem;
  flex-grow: 1;
}

.kg-bookmark-thumbnail img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  object-fit: cover;
}

.kg-bookmark-author {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.kg-bookmark-publisher::before {
  content: '•';
  margin: 0 0.5em;
}

@media screen and (min-width: 445px) {
  .kg-image-card {
    margin: 2rem;
  }
}

// Gallery

.kg-gallery-container {
  display: flex;
  flex-direction: column;
  margin: 1.5em auto;
  max-width: 1040px;
  width: 100vw;
}

.kg-gallery-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.kg-gallery-image img {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
}

.kg-gallery-row:not(:first-of-type) {
  margin: 0.75em 0 0 0;
}

.kg-gallery-image:not(:first-of-type) {
  margin: 0 0 0 0.75em;
}
